// Redux action types
const SET_EVENT_DATA = "SET_EVENT_DATA";
const SET_LOADING = "SET_LOADING";
const SET_ERROR = "SET_ERROR";
const SET_TOTAL_PRICE = "SET_TOTAL_PRICE";
const SET_FLAT_DISCOUNT = "SET_FLAT_DISCOUNT";
const SET_TICKET_TYPES_DATA = "SET_TICKET_TYPES_DATA";
const SET_TICKET_TOTALS_DATA = "SET_TICKET_TOTALS_DATA";
const SET_EXISTING_EMAIL = "SET_EXISTING_EMAIL";
const SET_REGISTRATION_EMAIL = "SET_REGISTRATION_EMAIL";
const SET_NEW_REGISTRATION = "SET_NEW_REGISTRATION";
const SET_TRACKING_CODE = "SET_TRACKING_CODE";
const SET_NO_REFUNDS_POLICY = "SET_NO_REFUNDS_POLICY";
const SET_TOTAL_INCLUDED_IN_TICKET_PRICE = "SET_TOTAL_INCLUDED_IN_TICKET_PRICE";

// Redux action creators
export const setEventData = (data) => ({ type: SET_EVENT_DATA, payload: data });
export const setLoading = (isLoading) => ({
  type: SET_LOADING,
  payload: isLoading,
});
export const setError = (error) => ({ type: SET_ERROR, payload: error });
export const setTotalPrice = (price) => ({
  type: SET_TOTAL_PRICE,
  payload: price,
});
export const setTicketTypesData = (data) => ({
  type: SET_TICKET_TYPES_DATA,
  payload: data,
});
export const setTicketTotalsData = (data) => ({
  type: SET_TICKET_TOTALS_DATA,
  payload: data,
});
export const setExistingEmail = (email) => ({
  type: SET_EXISTING_EMAIL,
  payload: email,
});
export const setRegistrationEmail = (email) => ({
  type: SET_REGISTRATION_EMAIL,
  payload: email,
});
export const setNewRegistration = (isNewRegistration) => ({
  type: SET_NEW_REGISTRATION,
  payload: isNewRegistration,
});
export const setFlatDiscount = (discount) => ({
  type: SET_FLAT_DISCOUNT,
  payload: discount,
});
export const setTrackingCode = (trackingCode) => ({
  type: SET_TRACKING_CODE,
  payload: trackingCode,
});
export const setNoRefundsPolicy = (policy) => ({
  type: SET_NO_REFUNDS_POLICY,
  payload: policy,
});
export const setTotalIncludedInTicketPrice = (totalIncludedInTicketPrice) => ({
  type: "SET_TOTAL_INCLUDED_IN_TICKET_PRICE",
  payload: totalIncludedInTicketPrice,
});

const initialState = {
  eventData: [],
  isLoading: false,
  error: null,
  totalPrice: 0,
  flatDiscount: 0,
  ticketTypesData: {},
  ticketTotalsData: {},
  existingEmail: "",
  registrationEmail: "",
  isNewRegistration: false,
  trackingCode: null,
  noRefundsPolicy: false,
  totalIncludedInTicketPrice: 0,
};

// Reducer function
export default function eventDataReducer(state = initialState, action) {
  switch (action.type) {
    case SET_EVENT_DATA:
      return { ...state, eventData: action.payload };
    case SET_LOADING:
      return { ...state, isLoading: action.payload };
    case SET_ERROR:
      return { ...state, error: action.payload };
    case SET_TOTAL_PRICE:
      return { ...state, totalPrice: action.payload };
    case SET_TICKET_TYPES_DATA:
      return { ...state, ticketTypesData: action.payload };
    case SET_TRACKING_CODE:
      return { ...state, trackingCode: action.payload };
    case SET_TICKET_TOTALS_DATA:
      return { ...state, ticketTotalsData: action.payload };
    case SET_FLAT_DISCOUNT:
      return { ...state, flatDiscount: action.payload };
    case SET_EXISTING_EMAIL:
      return { ...state, existingEmail: action.payload };
    case SET_REGISTRATION_EMAIL:
      return { ...state, registrationEmail: action.payload };
    case SET_NEW_REGISTRATION:
      return { ...state, isNewRegistration: action.payload };
    case SET_NO_REFUNDS_POLICY:
      return { ...state, noRefundsPolicy: action.payload };
    case SET_TOTAL_INCLUDED_IN_TICKET_PRICE:
      return { ...state, totalIncludedInTicketPrice: action.payload };
    default:
      return state;
  }
}
