import React from "react";
import "./EventHeader.css";

const EventHeader = ({ eventData }) => {
  const shareEvent = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: eventData[0]?.name,
          url: window.location.href,
        });
        console.log("Page shared successfully");
      } catch (error) {
        console.error("Error sharing the page:", error);
      }
    } else {
      copyToClipboard(window.location.href);
      alert("URL copied to clipboard. You can now paste and share it.");
    }
  };

  const copyToClipboard = (text) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  };

  return (
    <div className="container9">
      <div className="sizesm-iconfalse-colorgra">
        <div className="text6">
          {eventData[0]?.category ?? eventData[0]?.type ?? "No Data"}
        </div>
      </div>
      <div className="content22">
        <div className="heading-and-supporting-text5">
          <div className="heading-and-subheading">
            {/* <div className="heading5">{eventData[0]?.name}</div> */}
            <div className="heading5">
            The Early {" "}
              <text
                className="heading5-year"
                style={{
                  color: "var(--brand-primary-brand)",
                }}
              >
                Hook Up
              </text>
            </div>
          </div>
        </div>
        {/* <div className="share-01-parent">
          <img 
            className="share-01-icon" 
            alt="Share Event" 
            src="/share01.svg" 
            onClick={shareEvent}
          /> 
        </div> */}
      </div>
    </div>
  );
};

export default EventHeader;
