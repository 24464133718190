import Api from "../utils/Api";

function getStatus(response) {
    if (response.status === 200 || response.status === 204) {
      return { status: true, response: response.response };
    } else if (response.status === 502) {return { status: false };
    } else {
      if (response.status === 400) {
        return { status: false, response: response.response, isWithError: true };
      } else if (response.status === 428) {
         return { status: false, response: response.response };
      } else {
        return { status: false, response: response };
      }
    }
  }

  export async function getEventDetails(params) {
    try {
      const res = await Api.GET(params==null ?'getevents/5' : `getevents/5?trackingCode=${params}`);
      console.log("getEventDetails", params);
      if (res) {
       return getStatus(res);
      } else {
        return { status: false, message: res.message };
      }
    } catch (error) {
      console.log("getEventDetails error: ", error);
      return { status: false, message: "Oops, Something Went Wrong" };
    }
}


// email existence check
export async function emailInUse(params) {
  try {
    const res = await Api.POST("api/Register/EmailInUse", params);
    if (res) {
      console.log("emailInUse", res);
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    console.log("emailInUse error: ", error);
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// sign up
export async function signUp(params) {
  try {
    const res = await Api.POST("api/Register/MobileWallet", params);
    if (res) {
      console.log("signUp****", res);
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    console.log("signUpMobileWallet error: ", error);
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// contactPage

export async function contactPage(fromEmailAddress, userNames, comment) {
  try {
    const endpoint = `api/Order/ReportIssue/${fromEmailAddress}/${userNames}`;
    const body = { fromEmailAddress, userNames, comment }; 

    console.log("Sending payload to server:", body); 

    const res = await Api.POST(endpoint, JSON.stringify(body), {
      headers: {
        'Content-Type': 'application/json'
      }
    }); 

    if (res) {
      console.log("contactPage response:", res);
      return getStatus(res); 
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    console.log("contactPage error: ", error);
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// export async function contactPage(fromEmailAddress, userNames, comment) {
//   try {
//     const endpoint = `api/Order/ReportIssue/${encodeURIComponent(fromEmailAddress)}/${encodeURIComponent(userNames)}`;
//     const body = JSON.stringify({ comment });

//     console.log("Sending payload to server:", { fromEmailAddress, userNames, comment }); 

//     const res = await Api.POST(endpoint, body, {
//       headers: {
//         'Content-Type': 'application/json'
//       }
//     }); 

//     if (res) {
//       console.log("contactPage response:", res);
//       return getStatus(res); 
//     } else {
//       return { status: false, message: res.message };
//     }
//   } catch (error) {
//     console.log("contactPage error: ", error);
//     return { status: false, message: "Oops, Something Went Wrong" };
//   }
// }

// create order
export async function createOrder(params) {
  try {
    const res = await Api.POST("api/Order/Create", params);
    if (res) {
      console.log("createOrder", res);
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    console.log("createOrder error: ", error);
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// update order
export async function updateOrder(params) {
  try {
    const res = await Api.PUT("api/Order/Update", params);
    if (res) {
      console.log("updateOrder", res);
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    console.log("updateOrder error: ", error);
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}
