import React from "react";
import "./DisclaimerText.css";
const DisclaimerText = () => {
  return (
    <div className="supporting-text22">
      This website facilitates the sale of tickets by event organizers. All
      tickets are sold by the respective event organizers, and this platform
      acts as a facilitator for the transaction. By purchasing you’ll receive an
      account, and agree to our general{" "}
      <a
        href="/terms"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          cursor: "pointer",
          textDecoration: "none",
          color: "var(--brand-primary-brand)",
        }}
      >
        Terms of Use
      </a>
      , Privacy Policy and the{" "}
      <a
        href="/terms"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          cursor: "pointer",
          textDecoration: "none",
          color: "var(--brand-primary-brand)",
        }}
      >
        Ticket Purchase Terms
      </a>
      . We process your personal data in accordance with our Privacy Policy.
    </div>
  );
};

export default DisclaimerText;
