import React from "react";
import "./CommonButton.css";

const CommonButton = ({ text, icon, onClick, backgroundColor, borderColor, fontColor, width, height, fontSize, fontFamily, onMouseDown }) => {
  const buttonStyle = {
    backgroundColor: backgroundColor || "var(--brand-primary-brand)",
    borderColor: borderColor || "var(--brand-primary-brand)",
    color: fontColor || "var(--monochrome-white)",
    width: width || 'auto', 
    height: height || 'auto', 
    fontSize: fontSize || 'inherit', 
    fontFamily: fontFamily || 'Poppins'
  };

  return (
    <button
      className="simple-button"
      style={buttonStyle}
      onClick={onClick}
      onMouseDown={onMouseDown}
    >
      {icon && <img src={icon} alt="" className="button-icon" />}
      {text}
    </button>
  );
};

export default CommonButton;
