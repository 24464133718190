import { useSelector } from "react-redux";
import "./EventFormContainer.css";

import dayjs from "dayjs";

const EventFormContainer = ({ whichmodule }) => {
  const eventData = useSelector((state) => state.eventData);

  // function formatDate(input) {
  //   const [day, month] = input.split(" ").map(Number);
  //   const date = new Date(2020, month - 1, day);
  //   const getOrdinalNum = (n) => {
  //     let s = ["th", "st", "nd", "rd"],
  //       v = n % 100;
  //     return n + (s[(v - 20) % 10] || s[v] || s[0]);
  //   };

  //   const formattedDay = getOrdinalNum(day);
  //   const monthName = date.toLocaleString("en", { month: "long" });

  //   return `${formattedDay} ${monthName}`;
  // }

  // const formatEventDates = () => {
  //   if (!eventData[0]?.startDate || !eventData[0]?.endDate) {
  //     return "Date not available";
  //   }

  //   const startYear = new Date(eventData[0].startDate).getFullYear();
  //   const endYear = new Date(eventData[0].endDate).getFullYear();

  //   const startMonth = new Date(eventData[0].startDate).getMonth() + 1;
  //   const endMonth = new Date(eventData[0].endDate).getMonth() + 1;

  //   const startDay = new Date(eventData[0].startDate).getDate();
  //   const endDay = new Date(eventData[0].endDate).getDate();

  //   var formattedStartDate = formatDate(`${startDay} ${startMonth}`);
  //   var formattedEndDate = formatDate(`${endDay} ${endMonth}`);

  //   if (startYear !== endYear) {
  //     formattedStartDate += ` ${startYear}`;
  //     formattedEndDate += ` ${endYear}`;
  //   } else {
  //     formattedEndDate += ` ${endYear}`;
  //   }

  //   return `${formattedStartDate} - ${formattedEndDate}`;
  // };

  function formatDate(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const getOrdinalNum = (n) => {
      let s = ["th", "st", "nd", "rd"],
        v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    };

    const formattedDay = getOrdinalNum(day);
    const monthName = date.toLocaleString("en", { month: "long" });

    return `${formattedDay} ${monthName} ${year}`;
  }

  const formatEventDates = () => {
    if (!eventData[0]?.startDate || !eventData[0]?.endDate) {
      return "Date not available";
    }

    const startDate = new Date(eventData[0].startDate);
    const endDate = new Date(eventData[0].endDate);

    // Check if the end date/time is greater than 6 AM
    const sixAmOnEndDate = new Date(endDate);
    sixAmOnEndDate.setHours(6, 0, 0, 0); // Set to 6 AM on the end date
    const showEndDate = endDate > sixAmOnEndDate;

    var formattedStartDate = formatDate(startDate);

    if (showEndDate) {
      var formattedEndDate = formatDate(endDate);
      return `${formattedStartDate} - ${formattedEndDate}`;
    } else {
      return formattedStartDate;
    }
  };

  return (
    <div className="modal-header content-overall">
      <div className="content24">
        {whichmodule === "ticket" && (
          <img
            className="content-icon1"
            alt=""
            src={
              eventData[0]?.imageUri
                ? `${process.env.REACT_APP_API_URL}${eventData[0].imageUri}`
                : "/content@2x.png"
            }
          />
        )}
        <div className="text-and-supporting-text1">
          <div className="text24">{eventData[0]?.name}</div>
          <div className="calendar-container">
            <img className="calendar-icon2" alt="" src="/calendar.svg" />
            <div className="supporting-text27">{formatEventDates()}</div>
          </div>
          <div className="calendar-container">
            <img className="calendar-icon2" alt="" src="/markerpin03.svg" />
            <div className="supporting-text27">
              {eventData[0]?.venue?.address?.addressLine1 +
                ", " +
                eventData[0]?.venue?.address?.addressLine2 +
                ", " +
                eventData[0]?.venue?.address?.city +
                ", " +
                eventData[0]?.venue?.address?.postalCode}
            </div>
          </div>
        </div>
        {whichmodule === "payment" && (
          <img
            className="content-icon1 cont-payment"
            alt=""
            src={
              eventData[0]?.imageUri
                ? `${process.env.REACT_APP_API_URL}${eventData[0].imageUri}`
                : "/content@2x.png"
            }
          />
        )}
      </div>
    </div>
  );
};

export default EventFormContainer;
