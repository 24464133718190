import React from "react";
import "./Footer.css";
import "./FooterLink.css";
import CommonButton from "../Common/CommonButton";
import { Link } from "react-router-dom";

const Footer = () => {
  const navigateToHome = () => {
    const url = "https://www.twotixx.com/";
    if (url) {
      window.open(url, "_blank").focus();
    } else {
    }
  };

  const navigateToContact = () => {
    const url = "https://www.twotixx.com/get-demo.html";
    if (url) {
      window.open(url, "_blank").focus();
    } else {
    }
  };

  const navigateToAppleStore = () => {
    const url = "https://apps.apple.com/in/app/twotixx/id6451201695";
    if (url) {
      window.open(url, "_blank").focus();
    } else {
    }
  };

  const navigateToPlaystore = () => {
    const url = "https://play.google.com/store/apps/details?id=com.twotixx";
    if (url) {
      window.open(url, "_blank").focus();
    } else {
      console.log("No URL available");
    }
  };

  const navigateToPrivacyPolicy = () => {
    const url = "https://www.twotixx.com/privacyPolicy.html";
    if (url) {
      window.open(url, "_blank").focus();
    } else {
      console.log("No URL available");
    }
  };

  const text2Style = {
    fontWeight: "unset",
    fontFamily: "Poppins",
    letterSpacing: "0.02em",
    textTransform: "uppercase",
  };
  return (
    <div className="footer-d content5">
      <div className="container1 ">
        <div className="content4">
          <div className="logo-and-links">
            <div className="logo-and-supporting-text">
              <div className="footerLogo">
                <img className="iconfooterLogo" alt="" src="/icon1.svg" />
              </div>
            </div>

            <div className="footer-links-section">
              <div className="footer-links-company">
                <h4>Company</h4>
                <Link to="/terms">
                  <text>Terms</text>
                </Link>
              </div>
              <div className="footer-links-software">
                <h4>Software</h4>
                <Link to="/app">
                  <text>App</text>
                </Link>
              </div>
              <div className="footer-links-contact">
                <h4>Contact</h4>
                <Link to="/contact">
                  <text>Get in touch</text>
                </Link>
              
              </div>
            </div>
          </div>
          <div className="app-store-actions">
            <div className="heading4">Get the app</div>
            <div className="actions">
              <CommonButton
                text="Download on iOS"
                onClick={navigateToAppleStore}
                backgroundColor="#2D72E2"
                borderColor="#2D72E2"
                fontColor="#FFFFFF"
                icon="/apple.svg"
              />
              <CommonButton
                text="Download on Android"
                onClick={navigateToPlaystore}
                backgroundColor="#2D72E2"
                borderColor="#2D72E2"
                fontColor="#FFFFFF"
                icon="/android.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
