import React from "react";
import "./Modal.css";
const Modal = ({ children, onClose }) => {
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>

      <div className="modal-top-logoClose">
            <div className="navBarLogo">
                <img className="iconnavBarLogo" alt="" src="/icon1.svg" />
              </div>
              <div className="modal-close-button-block">
                <button className="modal-close-button" onClick={onClose}><img src="/x-close.svg"/></button>
              </div>
              
        </div>
        {children}        
        
      </div>
    </div>
  );
};

export default Modal;
