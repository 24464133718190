import {
  upperCaseReg,
  lowerCaseReg,
  numberReg,
  symbolReg,
} from "../constants/regex";

const usePasswordValidation = (password) => {
  //   let errors = [];

  //   if (!password || password.length < 8) {
  //     errors.push("Password must be at least 8 characters long");
  //   }
  //   if (!upperCaseReg.test(password)) {
  //     errors.push("include at least one uppercase letter");
  //   }
  //   if (!lowerCaseReg.test(password)) {
  //     errors.push("include at least one lowercase letter");
  //   }
  //   if (!numberReg.test(password)) {
  //     errors.push("include at least one number");
  //   }
  //   if (!symbolReg.test(password)) {
  //     errors.push("include at least one special character");
  //   }

  //   return {
  //     isValid: errors.length === 0,
  //     errors: errors.join(", "),
  //   };
  let errors = [];
  let unmetCriteria = [];

  if (!password || password.length < 8) {
    unmetCriteria.push("Length");
  }
  if (!upperCaseReg.test(password)) {
    unmetCriteria.push("Upper");
  }
  if (!lowerCaseReg.test(password)) {
    unmetCriteria.push("Lower");
  }
  if (!numberReg.test(password)) {
    unmetCriteria.push("Number");
  }
  if (!symbolReg.test(password)) {
    unmetCriteria.push("Symbol");
  }

  return {
    isValid: unmetCriteria.length === 0,
    errors: errors.join(", "),
    unmetCriteria,
  };
};

export default usePasswordValidation;
