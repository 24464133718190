import EventFormContainer from "../components/EventFormContainer";
import "./PaymentStepper.css";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { TextField, Grid, InputAdornment } from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CommonButton from "../../Common/CommonButton";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useSelector } from "react-redux";
import { createOrder } from "../../../services/api";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import DisclaimerText from "../components/DisclaimerText";

const useStyles = makeStyles({
  root: {
    "& .MuiFormControl-root": {
      margin: "10px 0",
    },
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px",
      height: "30px",
      alignItems: "center",
    },
    "&:first-child .MuiOutlinedInput-root": {
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
    },
    "&:last-child .MuiOutlinedInput-root": {
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:not(:first-child)": {
      marginLeft: "-1px",
    },
    "& .MuiOutlinedInput-input::placeholder": {
      fontFamily: "Poppins",
      opacity: 1,
      fontSize: "16px",
      textTransform: "sentence-case",
      color: "#A7A7A7",
    },
    flex: 1,
  },
  postcodeInput: {
    "& .MuiOutlinedInput-input::placeholder": {
      fontFamily: "Poppins",
      opacity: 1,
      fontSize: "16px",
      textTransform: "sentence-case",

      color: "#A7A7A7",
    },
  },
  cardNumberInput: {
    flex: "1 1 80%",
  },
  expiryInput: {
    flex: "1 1 26%",
  },
  cvcInput: {
    flex: "1 1 18%",
  },
  label: {
    fontFamily: 'Poppins',
    color: "#000000",
    fontSize: "14px",
    marginBottom: "5px",
    textTransform: "uppercase",
  },
});

const stripePromise = loadStripe("pk_live_51O6ThzD7zl5JDYSWxkv8eCcEbxtcpmLoTimI9k4ZydcFt5YLkJx28f9BjvcW7BB0fUBbAqdjZlAXRgibs7aS75sN00aOnDDWB1");

const finalizePaymentOnServer = async ({
  paymentMethodId,
  orderId,
  totalPrice,
}) => {
  try {
    const response = await fetch("/path/to/your/payment/endpoint", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        paymentMethodId,
        orderId,
        totalPrice,
      }),
    });

    const responseData = await response.json();
    if (response.ok) {
      return { status: "success", data: responseData };
    } else {
      return { status: "error", message: responseData.message };
    }
  } catch (error) {
    console.error("Error finalizing payment:", error);
    return { status: "error", message: "Network error or server is down" };
  }
};

const PaymentForm = ({ onSuccessfulPayment, orderParams }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handlePaymentSubmission = async () => {
    if (!stripe || !elements) {
      console.error("Stripe has not loaded");
      return;
    }

    const cardElement = elements.getElement(CardElement);

    // Create a Payment Method
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.error("Error creating payment method:", error);
      return;
    }

    // Send payment details to the server to finalize payment
    const paymentResult = await finalizePaymentOnServer({
      paymentMethodId: paymentMethod.id,
      ...orderParams,
    });

    if (paymentResult.status === "success") {
      onSuccessfulPayment();
    } else {
      console.error("Payment failed:", paymentResult.message);
    }
  };

  return (
    <div>
      <CardElement />
      <CommonButton text="Pay" onClick={handlePaymentSubmission} />
    </div>
  );
};

const PaymentStepper = ({ onContinue }) => {
  const classes = useStyles();
  const [cardNumber, setCardNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvc, setCvc] = useState("");
  const [focus, setFocus] = useState("");

  const totalPrice = useSelector((state) => state.totalPrice);
  const eventData = useSelector((state) => state.eventData);
  const ticketTotalsData = useSelector((state) => state.ticketTotalsData);
  const flatDiscount = useSelector((state) => state.flatDiscount);
  const trackingCode = useSelector((state) => state.trackingCode);

  const existingEmail = useSelector((state) => state.existingEmail);
  const registrationEmail = useSelector((state) => state.registrationEmail);
  const isNewRegistration = useSelector((state) => state.isNewRegistration);

  console.log("setRegistrationEmail", existingEmail, registrationEmail);

  const [cardErrors, setCardErrors] = useState({
    cardNumber: "",
    expiry: "",
    cvc: "",
  });

  const allTicketTypes = eventData[0]?.eventTicketTypeCategories.reduce(
    (acc, category) => [...acc, ...category.ticketTypes],
    []
  );

  const getCurrencySymbol = () => {
    const firstTicketType =
      allTicketTypes.length > 0 ? allTicketTypes[0] : null;
    return firstTicketType ? firstTicketType.ticketPrice.currency.symbol : "";
  };

  const renderTicketDetails = () => {
    return ticketTotalsData?.map((ticketType) => {
      const discountedTotal = Math.max(
        ticketType.total - flatDiscount * ticketType.count,
        0
      );

      return (
        <div key={ticketType.ticketTypeId} className="row2">
          <div className="text5">
            {ticketType.count} x {ticketType.name}
          </div>
          <div className="supporting-text16">
            {ticketType.currencySymbol}
            {discountedTotal.toFixed(2)}
          </div>
        </div>
      );
    });
  };

  const validateCardNumber = (number) => {
    return number.length === 16;
  };

  const validateExpiry = (expiry) => {
    return expiry.match(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/);
  };

  const validateCVC = (cvc) => {
    return /^\d{3,4}$/.test(cvc);
  };

  const handleCardInputChange = (value, type) => {
    let errors = { ...cardErrors };

    switch (type) {
      case "cardNumber":
        setCardNumber(value);
        errors.cardNumber = validateCardNumber(value)
          ? ""
          : "Invalid card number";
        break;
      case "expiry":
        setExpiry(value);
        errors.expiry = validateExpiry(value) ? "" : "Invalid expiry date";
        break;
      case "cvc":
        setCvc(value);
        errors.cvc = validateCVC(value) ? "" : "Invalid CVC";
        break;
      default:
        break;
    }
    setCardErrors(errors);
  };

  const submitOrder = async () => {
    const orderParams = {
      emailAddress: isNewRegistration ? registrationEmail : existingEmail,
      eventId: eventData[0]?.id,
      trackingCode: trackingCode,
      currencyId: allTicketTypes[0]?.ticketPrice.currency.id,
      orderTotal: totalPrice,
      orderItems: ticketTotalsData.map((ticketType) => ({
        eventTicketTypeId: ticketType.ticketTypeId,
        quantity: ticketType.count,
        bookingFee: ticketType.bookingFee,
        price: ticketType.price,
        total: totalPrice,
      })),
    };
    console.log("OrderParams", orderParams);

    try {
      const orderResponse = await createOrder(orderParams);
      // console.log("OrderResponse", orderResponse.response.orderId);
      //send orderResponse.response.orderId or orderResponse.response.orderId, cardNumber, expiry, cvc, totalPrice to stripe from here
      if (orderResponse && orderResponse.status) {
        console.log(" ", orderResponse.response.orderId);
        return { status: true, orderId: orderResponse.response.orderId };
      } else {
        return { status: false, message: "Order creation failed" };
      }
    } catch (error) {
      console.error("Order creation error:", error);
      return { status: false, message: "Error in order creation" };
    }
  };

  const handleCardDetailsSubmit = async () => {
    if (
      !validateCardNumber(cardNumber) ||
      !validateExpiry(expiry) ||
      !validateCVC(cvc)
    ) {
      console.error("Validation failed");
      return;
    }

    const orderResult = await submitOrder();
    if (orderResult.status) {
      const orderParams = {
        orderId: orderResult.orderId,
        totalPrice,
      };
      return (
        <PaymentForm
          onSuccessfulPayment={onContinue}
          orderParams={orderParams}
        />
      );
    } else {
      console.error("Failed to create order:", orderResult.message);
    }
  };

  return (
    <Elements stripe={stripePromise}>
      <div className="payment">
        <div className="modal-parent">
          <div className="modal">
            <EventFormContainer />
            <img className="divider-icon" alt="" src="/divider1.svg" />
            <div className="rows">
              {renderTicketDetails()}
              <div className="row1">
                <div className="text6">Total</div>
                <div className="supporting-text17">
                  {getCurrencySymbol()}
                  {totalPrice.toFixed(2)}
                </div>
              </div>
            </div>
            <div className="p-content5">
              <div className="supporting-text18">
                <span>
                  <span className="signed-in-as">Signed in as</span>
                  <span className="span">{` `}</span>
                </span>
                <span className="tom-solley">
                  {" "}
                  {isNewRegistration ? registrationEmail : existingEmail}
                </span>
              </div>
            </div>
            <div className="p-content6">
              <DisclaimerText/>
            </div>
          </div>
          <div className="p-content7">
            <div className="text7">Pay with card</div>
            <form className={classes.root} noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className={classes.label}>Card Number</div>
                  <TextField
                    error={!!cardErrors.cardNumber}
                    helperText={cardErrors.cardNumber}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CreditCardIcon />
                        </InputAdornment>
                      ),
                    }}
                    value={cardNumber}
                    // onChange={(e) => setCardNumber(e.target.value)}
                    // onFocus={() => setFocus("number")}
                    onChange={(e) =>
                      handleCardInputChange(e.target.value, "cardNumber")
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  <div className={classes.label}>MM/YY</div>
                  <TextField
                    error={!!cardErrors.expiry}
                    helperText={cardErrors.expiry}
                    placeholder="MM/YY"
                    variant="outlined"
                    fullWidth
                    value={expiry}
                    // onChange={(e) => setExpiry(e.target.value)}
                    // onFocus={() => setFocus("expiry")}
                    onChange={(e) =>
                      handleCardInputChange(e.target.value, "expiry")
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.label}>CVC</div>
                  <TextField
                    placeholder="CVC"
                    variant="outlined"
                    error={!!cardErrors.cvc}
                    helperText={cardErrors.cvc}
                    fullWidth
                    value={cvc}
                    // onChange={(e) => setCvc(e.target.value)}
                    // onFocus={() => setFocus("cvc")} send all the data like: cardNumber, expiry, cvc, totalPrice
                    onChange={(e) =>
                      handleCardInputChange(e.target.value, "cvc")
                    }
                  />
                </Grid>
                <Grid item xs={12} className={classes.cardPreview}>
                  <Cards
                    number={cardNumber}
                    name={""}
                    expiry={expiry}
                    cvc={cvc}
                    focused={focus}
                  />
                </Grid>
                <Grid item xs={12} className={classes.cardPreview}></Grid>
              </Grid>
            </form>

            <CommonButton
              text="Purchase"
              onClick={handleCardDetailsSubmit}
              width="100%"
              height="52px"
              fontSize="28px"
            />
          </div>
        </div>
      </div>
    </Elements>
  );
};

export default PaymentStepper;
