import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import GetAppStepper from "../GetAppStepper/GetAppStepper";
import PaymentStepper from "../../bookingComponent/PaymentStepper/PaymentStepper";
import Tickets from "../TicketStepper/Tickets";
import CustomerDetailsStepper from "../EventCustomerDetailsStepper/CustomerDetailsStepper";

const steps = ["Tickets", "Payment", "Get App"];

function CustomStepper() {
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});

  const getStepIconSrc = (step, index) => {
    const stepIcons = [
      ["/blue-tickets.svg", "/black-payments.svg", "/black-get-app.svg"],
      ["/green-tickets.svg", "/blue-payment.svg", "/black-get-app.svg"],
      // ['/green-tickets.svg', '/blue-payment.svg', '/black-get-app.svg'],
    ];
    return stepIcons[step][index];
  };

  const stepIcon = (props) => {
    const { icon } = props;
    const iconSrc = getStepIconSrc(activeStep, icon - 1);
    return <img src={iconSrc} alt="Step Icon" />;
  };

  const handleNext = () => {
    const newCompleted = { ...completed };
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);

    const nextStep = activeStep + 1;
    setActiveStep(nextStep);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return <Tickets onContinue={handleNext} />;
      case 1:
        return <CustomerDetailsStepper onContinue={handleNext} />;
      //   case 2:
      // return <PaymentStepper onContinue={handleNext} />;
      default:
        return <GetAppStepper onContinue={handleNext} />;
    }
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        nonLinear
        activeStep={activeStep}
        connector={<StepConnector />}
        sx={{
          display: "flex",
          justifyContent: "center",
          ".MuiStep-root": {
            padding: "0 8px",
          },
        }}
      >
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton onClick={() => setActiveStep(index)} disabled={true}>
              <StepLabel StepIconComponent={stepIcon} />
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        <Typography sx={{ mt: 2, mb: 1 }}>
          {renderStepContent(activeStep)}
        </Typography>
      </div>
    </Box>
  );
}

export default CustomStepper;

const StepConnector = () => null;
