import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { Provider } from "react-redux";
import EventDetails from "./pages/EventDetails/EventDetails";
import store from "./utils/store";
import SuccessPage from "./components/bookingComponent/Modal/SuccessPage";
import CancelPage from "./components/bookingComponent/Modal/CancelPage";
import Contact from "./pages/Contact/Contact";
import AppPage from "./pages/App/AppPage";
import Terms from "./pages/TermsnCond/Terms";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<EventDetails />} />
            {/* <Route path="*" element={<h1> Page Not Found !!</h1>} /> */}
            <Route path="/getevents" element={<EventDetails />} />
            <Route path="/success" element={<SuccessPage />} />
            <Route path="/cancel" element={<CancelPage/>} />
            <Route path="/contact" element={<Contact />} />
           <Route path="/app" element={<AppPage/>} />
           <Route path="/terms" element={<Terms/>} />
          </Routes>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
