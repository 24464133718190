const stripe = require("stripe")(
  "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"
);
const noRefundsPolicy = true; 
const CreateStripeSession = async (formData) => {
  const redirectURL =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000"
      : "https://theearlyhookup.twotixx.com";

  let description = `The Early Hook Up is back for its second year at Mutt Motorcycles HQ. The show includes some of the best choppers in the UK, bands, booze and a lot of fun! - Saturday 12th May - Doors open 10am - Ends 1am - Proof of age may be required - Children under 16 go free with a full paying adult, no child ticket required.`;
  if (noRefundsPolicy) {
    description += " This event has a no refund policy.";
  }
  console.log("No refunds policy:", noRefundsPolicy);

  try {
    const session = await stripe.checkout.sessions.create({
      payment_method_types: ["card"],
      line_items: [
        {
          price_data: {
            currency: formData.currency || "GBP",
            unit_amount: Math.round(parseFloat(formData.amount) * 100),
            product_data: {
              name: "THE EARLY HOOK UP",
              description: description,
            },
          },
          quantity: 1,
        },
      ],
      mode: "payment",
      billing_address_collection: "required",
      customer_email: formData?.email,
      success_url: redirectURL + "/success",
      cancel_url: redirectURL + "/cancel",
      metadata: {
        orderId: formData?.orderId,
        email: formData?.email,
      },
    });
    return { id: session.id, orderId: formData.orderId };
  } catch (error) {
    console.error("Error creating Stripe session:", error);
    throw error;
  }
};

export default CreateStripeSession;
