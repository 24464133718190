import React, { useEffect, useState, useMemo } from "react";
import "./EventDetails.css";
import { useSelector, useDispatch } from "react-redux";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import EventHeader from "./EventHeader";
import CommonButton from "../../components/Common/CommonButton";
import Modal from "../../components/bookingComponent/Modal/Modal";
import CustomStepper from "../../components/bookingComponent/Modal/CustomStepper";
import { getEventDetails } from "../../services/api";
import dayjs from "dayjs";
import loaderGif from "../../assets/gif/animation_6.gif";
import {
  setEventData,
  setLoading,
  setError,
  setTrackingCode,
  setNoRefundsPolicy,
} from "../../reducers/eventDataReducer";

const EventDetails = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const isLoading = useSelector((state) => state.isLoading);
  const eventData = useSelector((state) => state.eventData);
  const error = useSelector((state) => state.error);
  const dispatch = useDispatch();
  const noRefundsPolicy = useSelector((state) => state.noRefundsPolicy);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const trackingCode = params.get("utm");

    if (trackingCode) {
      dispatch(setTrackingCode(trackingCode));
    } else {
      console.log("No tracking code found in URL");
    }
  }, [dispatch]);

  useEffect(() => {
    // if (eventData.refundPolicy === "No refunds") {
    //   dispatch(setNoRefundsPolicy(true));
    // }
    dispatch(setNoRefundsPolicy(true));
  }, [dispatch]);
  // [dispatch, eventData.refundPolicy]);

  const fetchEventData = async () => {
    dispatch(setLoading(true));
    try {
      const params = new URLSearchParams(window.location.search);
      const trackingCode = params.get("utm");
      const result = await getEventDetails(trackingCode);
      console.log("eventDataResult", result);
      if (result.status) {
        dispatch(setEventData(result.response));
        sessionStorage.setItem("eventData", JSON.stringify(result.response));
      } else {
        dispatch(setError(result.message || "Failed to fetch event details"));
      }
    } catch (err) {
      dispatch(setError("Error fetching data"));
    } finally {
      dispatch(setLoading(false));
      setDataFetched(true);
    }
  };

  useEffect(() => {
    const timer = setTimeout(fetchEventData, 1000);
    return () => clearTimeout(timer);
  }, [dispatch]);

  // if (isLoading) {
  //   return (
  //     <div className="loader-container">
  //       <CircularProgressWithLabel />
  //     </div>
  //   );
  // }

  if (isLoading || !dataFetched) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100vh",
          backgroundColor: "#4688F3",
        }}
      >
        <img
          src={loaderGif}
          alt="Loading..."
          style={{ width: "150px", height: "auto" }}
        />
      </div>
    );
  }
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleTextClick = () => {
    const url = eventData[0]?.promoter?.url;
    if (url) {
      window.open(url, "_blank").focus();
    } else {
      console.log("No URL available");
    }
  };

  const navigateToPlaystore = () => {
    const url = "https://play.google.com/store/apps/details?id=com.twotixx";
    if (url) {
      window.open(url, "_blank").focus();
    } else {
      console.log("No URL available");
    }
  };

  const navigateToAppleStore = () => {
    const url = "https://apps.apple.com/in/app/twotixx/id6451201695";
    if (url) {
      window.open(url, "_blank").focus();
    } else {
      console.log("No URL available");
    }
  };
  // function formatDate(input) {
  //   const [day, month] = input.split(" ").map(Number);
  //   const date = new Date(2020, month - 1, day);
  //   const getOrdinalNum = (n) => {
  //     let s = ["th", "st", "nd", "rd"],
  //       v = n % 100;
  //     return n + (s[(v - 20) % 10] || s[v] || s[0]);
  //   };

  //   const formattedDay = getOrdinalNum(day);
  //   const monthName = date.toLocaleString("en", { month: "long" });

  //   return `${formattedDay} ${monthName}`;
  // }

  function formatDate(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const getOrdinalNum = (n) => {
      let s = ["th", "st", "nd", "rd"],
        v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    };

    const formattedDay = getOrdinalNum(day);
    const monthName = date.toLocaleString("en", { month: "long" });

    return `${formattedDay} ${monthName} ${year}`;
  }

  // const formatEventDates = () => {
  //   if (!eventData[0]?.startDate || !eventData[0]?.endDate) {
  //     return "Date not available";
  //   }

  //   const startYear = new Date(eventData[0].startDate).getFullYear();
  //   const endYear = new Date(eventData[0].endDate).getFullYear();

  //   const startMonth = new Date(eventData[0].startDate).getMonth() + 1;
  //   const endMonth = new Date(eventData[0].endDate).getMonth() + 1;

  //   const startDay = new Date(eventData[0].startDate).getDate();
  //   const endDay = new Date(eventData[0].endDate).getDate();

  //   var formattedStartDate = formatDate(`${startDay} ${startMonth}`);
  //   var formattedEndDate = formatDate(`${endDay} ${endMonth}`);

  //   if (startYear !== endYear) {
  //     formattedStartDate += ` ${startYear}`;
  //     formattedEndDate += ` ${endYear}`;
  //   } else {
  //     formattedEndDate += ` ${endYear}`;
  //   }

  //   return `${formattedStartDate} - ${formattedEndDate}`;
  // };

  const formatEventDates = () => {
    if (!eventData[0]?.startDate || !eventData[0]?.endDate) {
      return "Date not available";
    }

    const startDate = new Date(eventData[0].startDate);
    const endDate = new Date(eventData[0].endDate);

    // Check if the end date/time is greater than 6 AM
    const sixAmOnEndDate = new Date(endDate);
    sixAmOnEndDate.setHours(6, 0, 0, 0); // Set to 6 AM on the end date
    const showEndDate = endDate > sixAmOnEndDate;

    var formattedStartDate = formatDate(startDate);

    if (showEndDate) {
      var formattedEndDate = formatDate(endDate);
      return `${formattedStartDate} - ${formattedEndDate}`;
    } else {
      return formattedStartDate;
    }
  };

  return (
    <div className="eventDetails eventDetails-background">
      <Navbar />
      <div className="section">
        <div className="container home-two-block">
          <div className="section_home">
            <div>
              <EventHeader eventData={eventData} />
              <div className="lft-box">
                <div className="content lft-block-home">
                  <div className="event-image">
                    <img
                      className="content-icon event-details-mainImg"
                      alt=""
                      src={
                        eventData[0]?.imageUri
                          ? `${process.env.REACT_APP_API_URL}${eventData[0].imageUri}`
                          : null
                      }
                    />
                  </div>
                  {/* EventInfo */}
                  <div className="heading-and-supporting-text">
                    <div className="heading-event">Event details</div>
                    <div className="marker-pin-03-parent">
                      <img
                        className="marker-pin-03-icon"
                        alt=""
                        src="/calendar.svg"
                      />
                      <div className="supporting-text-parent">
                        <div className="supporting-text-venue">
                          {formatEventDates()}
                        </div>
                        <div className="supporting-text1">
                          Doors open at{" "}
                          {eventData[0]
                            ? dayjs(eventData[0].startDate).format("HH:mm")
                            : "Time not available"}
                        </div>
                      </div>
                    </div>
                    <div className="marker-pin-03-parent">
                      <img
                        className="marker-pin-03-icon"
                        alt=""
                        src="/markerpin03.svg"
                      />
                      <div className="supporting-text-parent">
                        {/* <div className="supporting-text-venue">
                          {eventData[0]?.venue?.address?.addressLine1 +
                            ", " +
                            eventData[0]?.venue?.address?.addressLine2 +
                            ", " +
                            eventData[0]?.venue?.address?.city +
                            ", " +
                            eventData[0]?.venue?.address?.postalCode}
                        </div> */}

                        <div className="supporting-text-venue">
                          {eventData[0]?.venue?.address?.addressLine1 +
                            ", " +
                            eventData[0]?.venue?.address?.addressLine2}
                        </div>

                        <div className="supporting-text1">
                          {/* {eventData[0]?.venue?.address?.postalCode +
                            ", " +
                            eventData[0]?.venue?.address?.country} */}
                          {eventData[0]?.venue?.address?.city +
                            ", " +
                            eventData[0]?.venue?.address?.postalCode}
                        </div>
                      </div>
                    </div>
                    <div className="heading-and-supporting-text-child" />
                  </div>

                  {/* About */}
                  <div className="heading-and-supporting-text1">
                    <div className="heading">About</div>

                    <div className="supporting-text4">
                      {eventData[0]?.description ? (
                        <>
                          {eventData[0].description
                            .split("\n\n")
                            .map((paragraph, pIndex) => {
                              if (pIndex === 0) {
                                return <p key={pIndex}>{paragraph}</p>;
                              } else {
                                return (
                                  <ul key={pIndex}>
                                    {paragraph
                                      .split("\n")
                                      .map((item, index) => (
                                        <li key={index}>{item}</li>
                                      ))}
                                  </ul>
                                );
                              }
                            })}
                        </>
                      ) : (
                        "No Data"
                      )}
                    </div>

                    <div className="verified-tick-parent">
                      <img
                        className="marker-pin-03-icon"
                        alt=""
                        src="/announcement.svg"
                      />
                      <div className="supporting-text6-presentedBy-text">
                        Promoted by
                        <div className="supporting-text6-promotedBy-text">
                          <a
                            href={
                              eventData[0]?.business?.socialMedia?.webUrl ?? "#"
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "var(--brand-primary-brand)",
                              cursor: "pointer",
                              textDecoration: "none",
                            }}
                            onClick={(e) => {
                              if (
                                !eventData[0]?.business?.socialMedia?.webUrl
                              ) {
                                e.preventDefault();
                              } else {
                                handleTextClick(e);
                              }
                            }}
                          >
                            {eventData[0]?.promoter?.name ??
                              eventData[0]?.promoterName ??
                              "No Data"}
                          </a>
                        </div>
                      </div>
                    </div>

                    {eventData[0]?.ticketRequirements?.kycRequired ||
                    eventData[0]?.ticketRequirements?.minimumAgeLimit ? (
                      <div className="background-style">
                        <div className="supporting-text4-attendees">
                          This event has requirements for all attendees:
                        </div>
                        {eventData[0]?.ticketRequirements?.kycRequired && (
                          <div className="verified-tick-parent">
                            <img
                              className="marker-pin-03-icon"
                              alt=""
                              src="/IconVerify.svg"
                            />
                            <div className="supporting-text6-id-verify">
                              ID verification
                            </div>
                          </div>
                        )}
                        {eventData[0]?.ticketRequirements?.minimumAgeLimit && (
                          <div className="verified-tick-parent">
                            <img
                              className="marker-pin-03-icon"
                              alt=""
                              src="/IconCalenderAge.svg"
                            />
                            <div className="supporting-text6-age">
                              {eventData[0].ticketRequirements.minimumAgeLimit}+
                              years old
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}

                    <div className="heading-and-supporting-text-child" />
                  </div>

                  {/* Venue */}
                  <div className="heading-and-supporting-text">
                    <div className="heading">Venue</div>
                    <div className="marker-pin-03-parent">
                      <img
                        className="marker-pin-03-icon"
                        alt=""
                        src="/markerpin03.svg"
                      />
                      <div className="supporting-text-parent">
                        <div className="supporting-text-venue">
                          {eventData[0]?.venue?.address?.addressLine1 +
                            ", " +
                            eventData[0]?.venue?.address?.addressLine2}
                        </div>
                        <div className="supporting-text1">
                          {eventData[0]?.venue?.address?.city +
                            ", " +
                            eventData[0]?.venue?.address?.postalCode}
                        </div>
                      </div>
                    </div>
                    <iframe
                      src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2491.243621698168!2d${
                        eventData[0]?.venue?.address?.longitude ?? "-0.1149"
                      }!3d${
                        eventData[0]?.venue?.address?.latitude ?? "51.4652"
                      }!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876045126f8939f%3A0x62a8f352fe9d9e53!2sO2%20Academy%20Brixton!5e0!3m2!1sen!2sid!4v1601138221085!5m2!1sen!2sid`}
                      width="100%"
                      height="288px"
                      frameBorder="0"
                      allowFullScreen=""
                      aria-hidden="false"
                      tabIndex="0"
                      className="custom-iframe"
                    />

                    <div className="clock-parent">
                      <img
                        className="marker-pin-03-icon"
                        alt=""
                        src="/markerpin03.svg"
                      />
                      <div className="supporting-text6">
                        Doors open at{" "}
                        {eventData[0]
                          ? dayjs(eventData[0].startDate).format("HH:mm")
                          : "Time not available"}
                      </div>
                    </div>
                    <div className="clock-parent">
                      <img
                        className="marker-pin-03-icon"
                        alt=""
                        src="/user03.svg"
                      />
                      <div className="supporting-text6">
                        {eventData[0]?.venue?.capacity || "Limited"} capacity
                      </div>
                    </div>
                    <div className="clock-parent">
                      <img
                        className="marker-pin-03-icon"
                        alt=""
                        src="/convert-card.svg"
                      />
                      {noRefundsPolicy && (
                        <div className="supporting-text6">No refunds</div>
                      )}
                    </div>
                    <div className="heading-and-supporting-text-child" />
                  </div>

                  {/* AppDetails */}
                  <div className="content1">
                    <div className="heading-and-supporting-text3">
                      <img
                        className="downloadComponent-icon"
                        alt=""
                        src="/icon1.svg"
                      />
                      {/* Your text content goes here */}

                      <div className="heading-and-badge">
                        <div className="heading2-download">
                          Download the Twotixx app
                        </div>
                      </div>
                      <div className="clock-parent">
                        <img
                          className="marker-pin-03-icon"
                          alt=""
                          src="/checkverified03.svg"
                        />
                        <div className="supporting-text-wrapper">
                          <div className="supporting-text6">Access tickets</div>
                        </div>
                      </div>
                      <div className="clock-parent">
                        <img
                          className="marker-pin-03-icon"
                          alt=""
                          src="/checkverified03.svg"
                        />
                        <div className="supporting-text6">Manage tickets</div>
                      </div>
                      <div className="clock-parent">
                        <img
                          className="marker-pin-03-icon"
                          alt=""
                          src="/checkverified03.svg"
                        />
                        <div className="supporting-text6">
                          Check-in at event
                        </div>
                      </div>
                    </div>
                    <div className="clock-parent">
                      <img
                        className="mobile-app-store-badge"
                        alt=""
                        src="/storeapp-store-stylebrand-sizelg.svg"
                        onClick={navigateToAppleStore}
                        style={{ cursor: "pointer" }}
                      />
                      <img
                        className="mobile-app-store-badge"
                        alt=""
                        src="/storegoogle-play-stylebrand-sizelg.svg"
                        onClick={navigateToPlaystore}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="rht-block-home">
                <div className="rht-block-img">
                  <img
                    className="content-icon event-details-mainImg"
                    alt=""
                    src={
                      eventData[0]?.imageUri
                        ? `${process.env.REACT_APP_API_URL}${eventData[0].imageUri}`
                        : "/content@2x.png"
                    }
                  />
                </div>

                <div className="content2 select-tickets-responsive">
                  <div className="heading-and-supporting-text4">
                    <div className="supporting-text15-from">tickets from</div>
                    <div className="heading-and-badge">
                      <div className="heading2">
                        {eventData[0]?.ticketPricesFrom?.value !==
                        eventData[0]?.ticketPricesFrom?.valueNet ? (
                          <>
                            <span
                              style={{
                                textDecoration: "line-through",
                                color: "#767676",
                              }}
                            >
                              {eventData[0]?.ticketPricesFrom?.currency?.symbol}
                              {eventData[0]?.ticketPricesFrom?.value.toFixed(2)}
                            </span>
                            <span>
                              {" "}
                              {eventData[0]?.ticketPricesFrom?.currency?.symbol}
                              {eventData[0]?.ticketPricesFrom?.valueNet.toFixed(
                                2
                              )}
                            </span>
                          </>
                        ) : (
                          <span>
                            {eventData[0]?.ticketPricesFrom?.currency?.symbol}
                            {eventData[0]?.ticketPricesFrom?.value.toFixed(2)}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <CommonButton
                    fontFamily="poppins"
                    text="Select tickets"
                    onClick={handleOpenModal}
                  />
                </div>
              </div>
            </div>
          </div>

          {isModalOpen && (
            <Modal onClose={handleCloseModal}>
              <CustomStepper eventData={eventData} />
            </Modal>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EventDetails;
